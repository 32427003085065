import React, { useEffect } from "react";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls//OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

const { URL } = window,
  loader = new STLLoader();

export const scene = new THREE.Scene(),
  renderer = new THREE.WebGLRenderer(),
  camera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    1,
    10000
  ),
  controls = new OrbitControls(camera, renderer.domElement),
  ambientLight = new THREE.AmbientLight(0x888888),
  directionalLight = new THREE.DirectionalLight(0x888888),
  animate = () => {
    requestAnimationFrame(animate);
    controls.update();
    directionalLight.position.copy(camera.position);
    renderer.render(scene, camera);
  },
  onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  },
  setPositions = mesh => {
    const box = new THREE.Box3(),
      fov = camera.fov * (Math.PI / 180),
      { max, min } = box.setFromObject(mesh),
      maxAxis = Math.max(max.x - min.x, max.y - min.y, max.z - min.z) / 2,
      distance = Math.abs(maxAxis / Math.sin(fov / 2));
    box.getCenter(controls.target);
    camera.position.copy(controls.target);
    camera.translateX(-distance);
    camera.translateZ(-distance);
    camera.target.copy(controls.target);
  };

export default ({ files }) => {
  useEffect(() => {
    const [file] = files,
      objectURL = URL.createObjectURL(file);

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);

    scene.add(ambientLight);
    scene.add(directionalLight);

    loader.load(objectURL, geometry => {
      const material = new THREE.MeshLambertMaterial({ color: 0xffffff }),
        mesh = new THREE.Mesh(geometry, material);

      mesh.rotateX(-Math.PI / 2);

      scene.add(mesh);
      setPositions(mesh);
    });

    animate();
    window.addEventListener("resize", onWindowResize, false);

    return () => {
      URL.revokeObjectURL(objectURL);
      window.removeEventListener("resize", onWindowResize, false);
    };
  }, [files]);

  return (
    <div
      className="Scene"
      ref={sceneElement => {
        sceneElement && sceneElement.appendChild(renderer.domElement);
      }}
    />
  );
};
