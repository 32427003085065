import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Container, Header, Icon, Segment } from "semantic-ui-react";

export default ({ setFiles }) => {
  const onDrop = useCallback(acceptedFiles => setFiles(acceptedFiles), [
      setFiles
    ]),
    { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: ["application/sla"],
      onDrop
    });

  return (
    <div className="Drop" {...getRootProps()}>
      <Container>
        <Segment
          padded="very"
          placeholder={true}
          raised={isDragActive}
          size="massive"
        >
          <Header icon={true}>
            <Icon name="file outline" />
            .stl
            {isDragActive ? (
              <Header.Subheader>Drop the file here ...</Header.Subheader>
            ) : (
              <Header.Subheader>
                Drag 'n' drop a file here, or click to select.
              </Header.Subheader>
            )}
          </Header>
          <Button primary={true}>Select File</Button>
          <input {...getInputProps()} />
        </Segment>
      </Container>
    </div>
  );
};
