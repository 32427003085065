import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";

import Drop from "./components/Drop";
import Scene from "./components/Scene";

function App() {
  const [files, setFiles] = useState(null);

  return (
    <div className="App">
      {files ? <Scene files={files} /> : <Drop setFiles={setFiles} />}
    </div>
  );
}

export default App;
